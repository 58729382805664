// Text utilities
// --------------------------------------------------
@mixin disable-text-select {
  -webkit-user-select: none;  /* Chrome all / Safari all */
     -moz-user-select: none;     /* Firefox all */
      -ms-user-select: none;      /* IE 10+ */
          user-select: none;          /* Likely future */
  cursor: default;
}

.hide {
  display: none;
}

.text-thin { font-weight: 300; }

.no-select {
    @include disable-text-select();
}

.no-wrap {
  white-space: nowrap;
}

// Make things clickable
// --------------------------------------------------
@mixin clickable {
  cursor: pointer;
}

.clickable {
    @include clickable();
}
