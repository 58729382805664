// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}

.promo {
  position: relative;
  color: #fff;
  background-image:url('/images/slate-bg@2x.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  background-color: #37373d;
}
